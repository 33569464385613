exports.components = {
  "component---src-pages-available-js": () => import("./../../../src/pages/available.js" /* webpackChunkName: "component---src-pages-available-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-sold-js": () => import("./../../../src/pages/sold.js" /* webpackChunkName: "component---src-pages-sold-js" */),
  "component---src-pages-statement-js": () => import("./../../../src/pages/statement.js" /* webpackChunkName: "component---src-pages-statement-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-piece-js": () => import("./../../../src/templates/piece.js" /* webpackChunkName: "component---src-templates-piece-js" */)
}

